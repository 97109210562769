import { consoleLogTransport, Logger, LoggerOptions } from "prpcow";

const isProduction = process.env.NODE_ENV === "production";
const LOG_LEVEL = isProduction ? "info" : "silly";

/**
 * Creates a logger instance with the specified options.
 * @param options - The options for the logger.
 * @returns A new logger instance.
 */
const createLogger = (options: LoggerOptions) =>
	new Logger({ level: LOG_LEVEL, callback: consoleLogTransport, ...options });

export default createLogger;
